
.i-sdpwrapper {
  min-width: 20rem;
  min-height: 20rem;
  
  .SingleDatePicker_picker .DayPicker_weekHeaders,
  .DateRangePicker_picker .DayPicker_weekHeaders {
    display: block;
  }

  .SingleDatePicker_picker .DayPicker_weekHeader_ul,
  .DateRangePicker_picker .DayPicker_weekHeader_ul {
    font-size: 1rem;
  }

  .DayPicker_weekHeader {
    top: 60px;
  }
}

.i-sdpwrapper .SingleDatePicker .SingleDatePickerInput {
  border: none;
}

.i-sdpwrapper .SingleDatePicker .SingleDatePickerInput .DateInput,
.i-sdpwrapper .SingleDatePicker .SingleDatePickerInput .DateInput .DateInput_input {
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  border: none;
}

.i-sdpwrapper .SingleDatePicker .SingleDatePickerInput .DateInput svg {
  display: none;
}

.i-sdpwrapper .SingleDatePicker .SingleDatePicker_picker {
  margin-top: -66px; /* TODO */
}

.SingleDatePicker_picker.SingleDatePicker_picker_1.SingleDatePicker_picker__directionLeft.SingleDatePicker_picker__directionLeft_2 {
  left: 0px !important;
}

.i-disable-calendar-input div.SingleDatePicker_picker.SingleDatePicker_picker_1.SingleDatePicker_picker__directionLeft.SingleDatePicker_picker__directionLeft_2.SingleDatePicker_picker__rtl.SingleDatePicker_picker__rtl_3 {
  left: unset !important;
  right: 0px !important;
}

.i-sdpwrapper.i--schedule .CalendarDay__highlighted_calendar {
  //color: red !important;
}

/*
.i-sdpwrapper.i--schedule .CalendarDay__highlighted_calendar:before {
  content: '';
  position: absolute;
  border-bottom: 2px solid red;
  width: 16px;
}
*/
